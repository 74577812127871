import React from 'react';

import { Link, styled, useTheme } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import { ReactComponent as DarkLogoImage } from 'src/images/logo-dark.svg';
import { ReactComponent as LightLogoImage } from 'src/images/logo-light.svg';

interface AppBarProps extends MuiAppBarProps {
  dark: boolean;
}

const StyledAppBar = styled(MuiAppBar)(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '60px',
  padding: '20px',
  boxShadow: 'none',
}));

const AppBar = ({ children, dark, ...props }: AppBarProps) => {
  const { palette } = useTheme();

  return (
    <StyledAppBar {...props} sx={{ background: dark ? palette.secondary.dark : palette.background.default }}>
      <Link href="/">{dark ? <DarkLogoImage width={125} /> : <LightLogoImage width={125} />}</Link>

      {children}
    </StyledAppBar>
  );
};

export default AppBar;
