import React from 'react';

import { CircularProgress, styled } from '@mui/material';
import MuiButton, { ButtonProps } from '@mui/material/Button';

const StyledButton = styled(MuiButton)<{ maxWidth?: string }>(({ theme, maxWidth }) => ({
  [theme.breakpoints.up('tablet')]: {
    maxWidth: maxWidth || '266px',
  },
}));

interface Props extends ButtonProps {
  loading?: boolean;
  maxWidth?: string;
}

const Button = ({ loading = false, maxWidth, children, disabled, ...props }: Props) => {
  return (
    <StyledButton {...props} disabled={disabled || loading} maxWidth={maxWidth}>
      {loading ? (
        <>
          <CircularProgress size={25} color="inherit" sx={{ marginRight: '15px' }} />
          Loading ..
        </>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default Button;
