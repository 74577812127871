import React from 'react';
import ReactGA from 'react-ga4';

import { Button, Typography } from '@mui/material';

import { DOWNLOAD_APP_LINK } from 'src/constants';
import { ReactComponent as PlayStoreIcon } from 'src/icons/play-store.svg';

const Auth0PlayStoreButton = () => {
  const goToGoogleStore = () => {
    ReactGA.event({
      category: 'Outbound Click',
      action: 'Go to Play Store',
      label: 'Play Store',
    });
    window.open(DOWNLOAD_APP_LINK.PlayStore, '_blank');
  };

  return (
    <Button onClick={goToGoogleStore} variant="contained" fullWidth>
      <Typography color="white">Play Store</Typography>
      <PlayStoreIcon className="backward-star" />
    </Button>
  );
};

export default Auth0PlayStoreButton;
