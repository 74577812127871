import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormControl, FormHelperText } from '@mui/material';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';

import { ReactComponent as ErrorIcon } from 'src/icons/error.svg';

import { InputIconButton } from './EmailInput';

const NameInput = ({ placeholder, defaultValue, ...props }: OutlinedInputProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    setError,
  } = useFormContext();

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const handleErrorIconClick = () => {
    setValue('name', '');
    setError('name', { message: 'Name is required' });
  };

  return (
    <FormControl fullWidth variant="outlined" error={!!errors.name && !isFocus} sx={{ marginBottom: '14px' }}>
      <OutlinedInput
        {...register('name')}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onClick={() => setIsFocus(true)}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        {...props}
      />

      {!!errors.name && <InputIconButton onClick={handleErrorIconClick}>{!isFocus && <ErrorIcon />}</InputIconButton>}

      {!!errors.name && !isFocus && <FormHelperText tabIndex={0}>{errors?.name?.message}</FormHelperText>}
    </FormControl>
  );
};

export default NameInput;
