import React, { HTMLAttributes } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Box, Link, styled } from '@mui/material';

import AppBar from 'src/components/AppBar';

interface StudentAuthLayoutProps {
  children: HTMLAttributes<HTMLDivElement>;
}

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 45,
  height: 'calc(100vh - 45px)',

  [theme.breakpoints.only('tablet')]: {
    padding: '0 40px',
  },
}));

const StudentAuthLayout = ({ children }: StudentAuthLayoutProps) => {
  const { logout } = useAuth0();

  return (
    <>
      <AppBar dark={false}>
        <Link
          onClick={() =>
            logout({
              returnTo:
                process.env.REACT_APP_ENV_NAME === 'prod' ? 'https://www.betterwise.com/' : window.location.origin,
            })
          }
          color="text.secondary"
        >
          Sign Out
        </Link>
      </AppBar>

      <Content>{children}</Content>
    </>
  );
};

export default StudentAuthLayout;
