import React, { FormEvent, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import useApi from 'src/api';
import { ReactComponent as ForwardStar } from 'src/images/star_forward.svg';

import Button from '../Button';
import ErrorMessage from '../ErrorMessage';

import { StyledForm } from './styled';

interface PaymentFormProps {
  membershipId: string;
  productId: string;
  seats: number;
  couponCode?: string;
}

const PaymentForm = ({ membershipId, seats, couponCode }: PaymentFormProps) => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { getMembership, addSeats } = useApi();

  const [message, setMessage] = useState<string | undefined>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormReady, setIsFormReady] = useState<boolean>(false);
  const [setupIntentId, setSetupIntentId] = useState<string>('');

  const { mutateAsync: addSeatsMutateAsync, isError: isAddSeatsError } = useMutation(addSeats, {
    onError: ({ response }) => {
      setMessage(response.data.message);
      setIsLoading(false);
    },
  });

  const { refetch } = useQuery('membership', getMembership, {
    onSuccess: ({ data }) => {
      if (data?.isActive) {
        history.replace('/dashboard');
      } else {
        setTimeout(() => {
          refetch();
        }, 1000);
      }
    },

    enabled: !!setupIntentId,
    refetchOnWindowFocus: false,
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    await addSeatsMutateAsync({ numberOfSeats: seats, couponCode });

    if (!isAddSeatsError) {
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          payment_method_data: {
            metadata: {
              membershipId,
            },
          } as { metadata: { membershipId: string }; billing_details: undefined },
        },
        redirect: 'if_required',
      });

      if (setupIntent?.id) {
        setSetupIntentId(setupIntent.id);
      }

      if (error?.message) {
        setMessage(error.message);
        setIsLoading(false);
      } else {
        sessionStorage.removeItem('clientSecret');
        sessionStorage.removeItem('couponCode');
      }
    }
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        {message && <ErrorMessage message={message} />}

        {!isFormReady && (
          <Box display="flex" alignItems="center" justifyContent="center" height="402px">
            <CircularProgress size={30} />

            <Typography ml="15px">Please wait ...</Typography>
          </Box>
        )}

        <PaymentElement
          onReady={() => setIsFormReady(true)}
          options={{ terms: { card: 'never' }, wallets: { applePay: 'never', googlePay: 'never' } }}
        />

        {isFormReady && (
          <Box display="flex" flexDirection="column" mt="58px">
            <Button variant="contained" type="submit" fullWidth disabled={isLoading} sx={{ marginBottom: '25px' }}>
              Subscribe
              {!isLoading && <ForwardStar className="forward-star" />}
            </Button>
          </Box>
        )}
      </StyledForm>

      <Backdrop open={isLoading}>
        <CircularProgress />
        <Typography>Please wait...</Typography>
      </Backdrop>
    </>
  );
};

export default PaymentForm;
