import React from 'react';

import { Box, styled } from '@mui/material';

interface ErrorMessageProps {
  message: string;
}

const Container = styled(Box)(({ theme }) => ({
  padding: '13px 16px',
  marginBottom: '21px',
  background: theme.palette.error.light,
  color: theme.palette.error.main,
  border: '1.5px solid',
  borderColor: theme.palette.error.main,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  wordBreak: 'break-word',
}));

const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return <Container>{message}</Container>;
};

export default ErrorMessage;
