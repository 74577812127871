import React, { Suspense } from 'react';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { RouteProps } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import { MembershipProvider } from 'src/providers/MembershipProvider';

import DashboardLayout from './DashboardLayout';

interface DashboardProps extends RouteProps {
  route?: RouteConfig;
}

const Dashboard = ({ route }: DashboardProps) => (
  <Suspense fallback={<LinearProgress />}>
    <MembershipProvider>
      <DashboardLayout>{route && renderRoutes(route.routes)}</DashboardLayout>
    </MembershipProvider>
  </Suspense>
);

export default Dashboard;
