/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import { Redirect } from 'react-router-dom';

import PostEmailVerified from './components/PostEmailVerified';
import ProtectedRoute from './components/ProtectedRoute';
import SponsorProtectedRoute from './components/SponsorProtectedRoute';
import StudentProtectedRoute from './components/StudentProtectedRoute';
import Auth from './layouts/Auth/Auth';
import AuthLogin from './layouts/Auth/AuthLogin';
import SponsorAuthLayout from './layouts/Auth/SponsorAuthLayout';
import StudentAuthLayout from './layouts/Auth/StudentAuthLayout';
import Dashboard from './layouts/Dashboard';
import Error from './layouts/Error';
import CreateMembership from './pages/Auth/CreateMembership';
import EmailVerified from './pages/Auth/EmailVerified';
import Logout from './pages/Auth/Logout';
import PaymentMethod from './pages/Auth/PaymentMethod';
import Plan from './pages/Auth/Plan';
import StudentDownloadApp from './pages/Auth/StudentDownloadApp';
import StudentEmailVerified from './pages/Auth/StudentEmailVerified';
import StudentInvite from './pages/Auth/StudentInvite';
import StudentSignup from './pages/Auth/StudentSignup';
import StudentTermsAndConditions from './pages/Auth/StudentTermsAndConditions';
import StudentVerifyEmail from './pages/Auth/StudentVerifyEmail';
import VerifyEmail from './pages/Auth/VerifyEmail';
import Privacy_Policy from './pages/Policy/Betterwise_Privacy_Policy.pdf';
import Terms_Of_Service from './pages/Policy/Betterwise_Terms_of_Service.pdf';
import TermsAndPrivacy from './pages/Policy/TermsAndPrivacy';

export default [
  {
    path: '/',
    exact: true,
    component: () => <AuthLogin />,
  },
  {
    path: '/logout',
    exact: true,
    component: () => <Logout />,
  },
  {
    path: '/post-email-verified',
    exact: true,
    component: () => <PostEmailVerified />,
  },
  {
    path: '/create-membership',
    exact: true,
    component: () => (
      <SponsorAuthLayout>
        <CreateMembership />
      </SponsorAuthLayout>
    ),
  },
  {
    path: '/signup-student',
    exact: true,
    component: () => <StudentSignup />,
  },
  {
    path: '/student-invite',
    exact: true,
    component: () => <StudentInvite />,
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: () => <TermsAndPrivacy pdfFile={Privacy_Policy} />,
  },
  {
    path: '/terms-of-service',
    exact: true,
    component: () => <TermsAndPrivacy pdfFile={Terms_Of_Service} />,
  },
  {
    path: '/sponsor',
    component: (props: RouteConfigComponentProps) => (
      <SponsorProtectedRoute
        render={() => (
          <SponsorAuthLayout>
            <Auth {...props} />
          </SponsorAuthLayout>
        )}
      />
    ),
    routes: [
      {
        path: '/sponsor/verify-email',
        exact: true,
        component: () => <VerifyEmail />,
      },
      {
        path: '/sponsor/email-verified',
        exact: true,
        component: () => <EmailVerified />,
      },
      {
        path: '/sponsor/plan',
        exact: true,
        component: () => <Plan />,
      },
      {
        path: '/sponsor/payment',
        exact: true,
        component: () => <PaymentMethod />,
      },
      {
        component: () => <Redirect to="/errors/not-found" />,
      },
    ],
  },
  {
    path: '/student',
    component: (props: RouteConfigComponentProps) => (
      <StudentProtectedRoute
        render={() => (
          <StudentAuthLayout>
            <Auth {...props} />
          </StudentAuthLayout>
        )}
      />
    ),
    routes: [
      {
        path: '/student/terms-and-conditions',
        exact: true,
        component: () => <StudentTermsAndConditions />,
      },
      {
        path: '/student/verify-email',
        exact: true,
        component: () => <StudentVerifyEmail />,
      },
      {
        path: '/student/email-verified',
        exact: true,
        component: () => <StudentEmailVerified />,
      },
      {
        path: '/student/download-app',
        exact: true,
        component: () => <StudentDownloadApp />,
      },
      {
        component: () => <Redirect to="/errors/not-found" />,
      },
    ],
  },
  {
    path: '/dashboard',
    component: (props: RouteConfigComponentProps) => <ProtectedRoute render={() => <Dashboard {...props} />} />,
    routes: [
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('./pages/Dashboard')),
      },
      {
        path: '/dashboard/account',
        exact: true,
        component: lazy(() => import('./pages/Dashboard/AccountPage')),
      },
      {
        path: '/dashboard/student-settings/update',
        exact: true,
        component: lazy(() => import('./pages/Dashboard/StudentSettingsPage/UpdateStudent')),
      },
      {
        path: '/dashboard/student-settings/remove',
        exact: true,
        component: lazy(() => import('./pages/Dashboard/StudentSettingsPage/RemoveStudent')),
      },
      {
        path: '/dashboard/invite-students',
        exact: true,
        component: lazy(() => import('./pages/Dashboard/InviteStudents')),
      },
      {
        path: '/dashboard/invite-sent',
        exact: true,
        component: lazy(() => import('./pages/Dashboard/InviteStudents/InviteSent')),
      },
      {
        path: '/dashboard/update-plan',
        exact: true,
        component: lazy(() => import('./pages/Dashboard/Billing/UpdatePlan')),
      },
      {
        path: '/dashboard/cancel-plan',
        exact: true,
        component: lazy(() => import('./pages/Dashboard/Billing/CancelRenewPlan/CancelPlan')),
      },
      {
        path: '/dashboard/renew-plan',
        exact: true,
        component: lazy(() => import('./pages/Dashboard/Billing/CancelRenewPlan/RenewPlan')),
      },
      {
        path: '/dashboard/change-payment-method',
        exact: true,
        component: lazy(() => import('./pages/Dashboard/Billing/PaymentMethod/ChangePaymentMethod')),
      },
      {
        path: '/dashboard/download-app',
        exact: true,
        component: lazy(() => import('./pages/Dashboard/InviteStudents/DownloadApp')),
      },
      {
        path: '/dashboard/delete-account',
        exact: true,
        component: lazy(() => import('./pages/Dashboard/AccountPage/DeleteAccount')),
      },
      {
        component: () => <Redirect to="/errors/not-found" />,
      },
    ],
  },
  {
    path: '/errors',
    component: Error,
    routes: [
      {
        path: '/errors/not-found',
        exact: true,
        component: lazy(() => import('./pages/Errors/NotFound')),
      },
      {
        path: '/errors/general',
        exact: true,
        component: lazy(() => import('./pages/Errors/GeneralError')),
      },
      {
        component: () => <Redirect to="/errors/not-found" />,
      },
    ],
  },
  {
    component: () => <Redirect to="/errors/not-found" />,
  },
] as RouteConfig[];
