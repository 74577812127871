import { styled } from '@mui/material';
import { Appearance } from '@stripe/stripe-js';

import theme from 'src/theme';

export const StyledForm = styled('form')({
  width: '100%',
});

export const appearance: Appearance = {
  theme: 'stripe',

  variables: {
    colorDanger: theme.palette.error.main,
    colorDangerText: theme.palette.error.main,
    focusOutline: theme.palette.secondary.main,
    borderRadius: '13px',
    focusBoxShadow: '3px',
  },

  rules: {
    '.Label': {
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '20px',
      letterSpacing: '0.4px',
      color: theme.palette.placeholder,
    },

    '.Input': {
      fontWeight: '500',
      fontSize: '17px',
      lineHeight: '22px',
      color: theme.palette.text.primary,
      border: '1px solid',
      borderColor: theme.palette.action.disabledBackground,
    },

    '.Input::placeholder': {
      color: theme.palette.placeholder,
      fontWeight: '500',
      fontSize: '17px',
      lineHeight: '22px',
      letterSpacing: '0.4px',
    },

    '.Input:focus': {
      boxShadow: 'none',
      border: '1px solid',
      borderColor: theme.palette.secondary.main,
    },

    '.Error': {
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '20px',
      letterSpacing: '0.4px',
    },
  },
};
