import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { Backdrop, Box, LinearProgress, Typography } from '@mui/material';
import moment from 'moment';

import useApi from 'src/api';
import { AssignedToStudent, Membership, Payment, PaymentMethod, Product, Seat, Member } from 'src/api/types';
import getTrialEndDate from 'src/utils/getTrialEndDate';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <MembershipProvider>.');
};

type MembershipContextType = {
  currentMember: Member;
  membership: Membership;
  seats: Seat[];
  students: AssignedToStudent[];
  seatsLeft: number;
  product: Product;
  paymentMethod: PaymentMethod;
  payments: Payment[];
  trialDaysLeft: number;
  refetchMembershipData: () => void;
};

const defaultValue: MembershipContextType = {
  currentMember: {
    email: '',
    memberId: '',
    membershipId: '',
    name: '',
    role: '',
    type: '',
  },
  membership: {
    id: '',
    paymentMethod: {} as PaymentMethod,
    payments: [],
    product: {} as Product,
    seatsById: [],
    sponsorId: '',
    isActive: false,
    createdAt: '',
    willRenew: false,
    isInTrial: true,
  },
  seats: [],
  students: [],
  seatsLeft: 0,
  product: {
    activated: false,
    description: '',
    paymentRecurrence: 'Yearly',
    name: '',
    productId: '',
    pricePerSeat: { currency: '', amount: '' },
  },
  paymentMethod: {
    membershipId: '',
    paymentMethodId: '',
    paymentInstrument: {
      cardBrand: '',
      last4: '',
      expirationMonth: 0,
      expirationYear: 0,
      fingerprint: '',
      paymentMethodId: '',
      type: '',
    },
  },
  payments: [],
  trialDaysLeft: 0,
  refetchMembershipData: stub,
};

export const MembershipContext = createContext(defaultValue);

MembershipContext.displayName = 'MembershipContext';

interface MembershipProviderProps {
  children: ReactNode;
}

export const MembershipProvider = ({ children }: MembershipProviderProps) => {
  const isAuthenticated = !!localStorage.getItem('AUTH_TOKEN');

  const { getMembership, getMemberRole } = useApi();
  const cachedMembership = useQueryClient().getQueryData<{ data: Membership }>('membership');

  const [currentMember, setCurrentMember] = useState<Member>(defaultValue.currentMember);
  const [membership, setMembership] = useState<Membership>(defaultValue.membership);
  const [seats, setSeats] = useState<Seat[]>(defaultValue.seats);
  const [students, setStudents] = useState<AssignedToStudent[]>(defaultValue.students);
  const [seatsLeft, setSeatsLeft] = useState<number>(defaultValue.seatsLeft);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(defaultValue.paymentMethod);
  const [payments, setPayments] = useState<Payment[]>(defaultValue.payments);
  const [product, setProduct] = useState<Product>(defaultValue.product);
  const [trialDaysLeft, setTrialDaysLeft] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useQuery('userRole', getMemberRole, {
    onSuccess: ({ data }) => {
      if (data.type) setCurrentMember(data);
    },

    enabled: isAuthenticated,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { refetch, isLoading, isError } = useQuery('membership', getMembership, {
    onSuccess: ({ data }) => {
      setMembershipData(data);
    },
    onError: ({ response }) => {
      setErrorMessage(response.data.message);
    },

    enabled: false,
    refetchOnWindowFocus: false,
  });

  const setMembershipData = useCallback((data: Membership) => {
    const seats = data.seatsById ? Object.values(data.seatsById) : [];
    const assignedStudents = seats
      .filter(({ assignedToStudent }) => assignedToStudent !== null)
      .map((student) => student.assignedToStudent);
    const now = new Date();
    const trialEndDate = getTrialEndDate(moment(data.createdAt));
    const trialDaysLeft = Math.round(moment.duration(trialEndDate.diff(now)).asDays());

    setMembership(data);
    setSeats(seats);
    setStudents(assignedStudents);
    setSeatsLeft(seats.length - assignedStudents.length);
    if (data.paymentMethod?.paymentMethodId && data.paymentMethod?.paymentInstrument?.paymentMethodId) {
      setPaymentMethod(data.paymentMethod);
    }
    setPayments(data.payments ?? []);
    setProduct(data.product);
    setTrialDaysLeft(trialDaysLeft);
  }, []);

  const refetchMembershipData = () => {
    refetch();
  };

  useEffect(() => {
    if (cachedMembership?.data) {
      setMembershipData(cachedMembership.data);
    }
  }, [cachedMembership, setMembershipData]);

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isError) {
    return (
      <Backdrop open={true}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography>Something went wrong, try again later</Typography>
          <Typography mt="10px">Error: {errorMessage}</Typography>
        </Box>
      </Backdrop>
    );
  }

  return (
    <MembershipContext.Provider
      value={{
        currentMember,
        membership,
        seats,
        students,
        seatsLeft,
        paymentMethod,
        product,
        payments,
        trialDaysLeft,
        refetchMembershipData,
      }}
    >
      {children}
    </MembershipContext.Provider>
  );
};

const useMembership = () => {
  return useContext(MembershipContext);
};

export default useMembership;
