export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;

export const PASSWORD_PATTERN = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&]{8,}$/;

export enum DOWNLOAD_APP_LINK {
  AppStore = 'https://apps.apple.com/us/app/betterwise/id1645343291',
  AppStoreTestFlight = 'https://testflight.apple.com/join/CM0esgh9',
  PlayStore = 'https://play.google.com/store/apps/details?id=com.betterwise.app',
}

export const INVITE_LINK = (membershipId: string, memberId: string) =>
  `${window.location.origin}/student-invite?membershipId=${membershipId}&memberId=${memberId}`;

export const ACCEPT_INVITE_LINK = (membershipId: string, memberId: string) =>
  `${window.location.origin}/signup-student?membershipId=${membershipId}&memberId=${memberId}`;

export const SUPPORT_LINK = 'mailto:support@betterwise.com';
