import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormControl, FormHelperText, IconButton, styled } from '@mui/material';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';

import { ReactComponent as ErrorIcon } from 'src/icons/error.svg';

export const InputIconButton = styled(IconButton)`
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;

  :hover {
    background: transparent;
  }

  svg {
    width: 24px;
  }
`;

const EmailInput = ({ placeholder, defaultValue, ...props }: OutlinedInputProps) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
    setValue,
    setError,
  } = useFormContext();

  const handleErrorIconClick = () => {
    setValue('email', '');
    setError('email', { message: 'Email address is required' });
  };

  return (
    <FormControl fullWidth variant="outlined" error={!!errors.email && !isFocus} sx={{ marginBottom: '14px' }}>
      <OutlinedInput
        {...register('email')}
        placeholder={placeholder}
        defaultValue={defaultValue}
        type="email"
        onClick={() => setIsFocus(true)}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        {...props}
      />

      {!!errors.email && <InputIconButton onClick={handleErrorIconClick}>{!isFocus && <ErrorIcon />}</InputIconButton>}

      {!!errors.email && !isFocus && <FormHelperText tabIndex={0}>{errors?.email?.message}</FormHelperText>}
    </FormControl>
  );
};

export default EmailInput;
