import React from 'react';

import { Box, Typography } from '@mui/material';

import useApi from 'src/api';
import Auth0AppStoreButton from 'src/Temp/Auth0AppStoreButton';
import Auth0PlayStoreButton from 'src/Temp/Auth0PlayStoreButton';

const StudentDownloadApp = () => {
  const { checkAuth } = useApi();
  checkAuth();

  return (
    <Box>
      <Typography textAlign="center" fontSize={35}>
        Download the App
      </Typography>

      <Typography textAlign="center" maxWidth="315px" my="18px">
        Use the links below or search “Betterwise” on your device’s application store.
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        height="109px"
        width="100%"
      >
        <Auth0AppStoreButton />
        <Auth0PlayStoreButton />
      </Box>
    </Box>
  );
};

export default StudentDownloadApp;
