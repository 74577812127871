import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import useApi from 'src/api';

const PostEmailVerified = () => {
  const history = useHistory();
  const { getMemberRole, checkAuth } = useApi();

  checkAuth();

  const { isLoading } = useQuery('userRole', getMemberRole, {
    onSuccess: ({ data }) => {
      if (data.type === 'com.mindsea.wa.membership.api.model.Sponsor') {
        history.replace('/sponsor/plan');
      } else {
        history.replace('/sponsor/plan');
      }
    },
    onError: ({ response }) => {
      if (response.status === 500) {
        history.replace('/errors/general');
      }

      if (response.status === 401) {
        history.replace('/create-membership');
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  return null;
};

export default PostEmailVerified;
