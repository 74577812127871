import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as EmailVerifiedImage } from 'src/images/email-verified.svg';
import Auth0Button from 'src/Temp/Auth0Button';
import Auth0PrimaryText from 'src/Temp/Auth0PrimaryText';
import Auth0SecondaryText from 'src/Temp/Auth0SecondaryText';

import { AuthPageWrapper } from './styled';

const StudentEmailVerified = () => {
  const history = useHistory();

  return (
    <AuthPageWrapper>
      <EmailVerifiedImage />

      <Auth0PrimaryText my="18px" textAlign="center">
        Email Verified
      </Auth0PrimaryText>

      <Auth0SecondaryText maxWidth="167px" textAlign="center" mb="30px">
        Thank you for verifying your email address.
      </Auth0SecondaryText>

      <Auth0Button fullWidth onClick={() => history.push('/student/download-app')} variant="contained">
        Next Step
      </Auth0Button>
    </AuthPageWrapper>
  );
};

export default StudentEmailVerified;
