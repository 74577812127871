import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';

import App from './App';
import Auth0ProviderWithHistory from './providers/Auth0ProviderWithHistory';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

import './index.css';
import './utils/i18n';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
