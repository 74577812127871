import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, Link, ListItem, ListItemText, Typography } from '@mui/material';

import StripeContainer from 'src/components/Stripe/StripeContainer';
import pluralize from 'src/utils/pluralize';

import { SummaryList } from './styled';

const PaymentMethod = () => {
  const history = useHistory();

  const { state } = useLocation<{
    membershipId: string;
    productId: string;
    clientSecret: string;
    seats: number;
    totalPrice: number;
    dueOn: string;
    couponCode?: string;
  }>();

  // const sanitizedDate = moment(state.dueOn, 'YYYY-MM-DD').format('MMMM DD, YYYY');

  return (
    <Box width="100%" maxWidth="500px" mt="60px">
      <Typography fontSize={40} lineHeight={1.2} mb={4}>
        Set up your billing
      </Typography>

      {/* <Typography variant="body1" mb={5}>
        No charge will be applied until {sanitizedDate}
      </Typography> */}

      <Box display="flex" alignItems="center" flexDirection="column">
        <SummaryList>
          <ListItem>
            <ListItemText primary="Plan" />

            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography variant="caption">{`${state.seats} ${pluralize('Seat', state.seats)}`}</Typography>

              <Link
                onClick={() => history.push('/sponsor/plan', { seats: state.seats })}
                component="button"
                variant="caption"
                color="text.secondary"
              >
                <small>edit</small>
              </Link>
            </Box>
          </ListItem>

          {state.couponCode && (
            <ListItem>
              <ListItemText primary="Coupon Code" />
              <Typography sx={{ marginLeft: '30px' }}>{state.couponCode}</Typography>
            </ListItem>
          )}

          {/* <ListItem>
            <ListItemText primary="Due Today" />

            <Typography> $0.00</Typography>
          </ListItem> */}

          <ListItem id="sub-total">
            <ListItemText primary={`Total`} />
            <Typography>{state.totalPrice}</Typography>
          </ListItem>
        </SummaryList>

        <StripeContainer {...state} />
      </Box>
    </Box>
  );
};

export default PaymentMethod;
