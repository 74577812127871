import React from 'react';

import { styled } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&.Mui-checked': {
    color: theme.palette.text.secondary,
  },
}));

const Auth0Checkbox = ({ ...props }: CheckboxProps) => {
  return <StyledCheckBox {...props} />;
};

export default Auth0Checkbox;
