import React from 'react';
import { useQuery } from 'react-query';
import { Route, RouteProps } from 'react-router';
import { useHistory } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import useApi from 'src/api';

const StudentProtectedRoute = (props: RouteProps) => {
  const history = useHistory();

  const { getMemberRole, checkAuth } = useApi();

  checkAuth();

  const memberId = sessionStorage.getItem('memberId');
  const membershipId = sessionStorage.getItem('membershipId');

  const { isLoading } = useQuery('userRole', getMemberRole, {
    onSuccess: ({ data }) => {
      if (data.type !== 'com.mindsea.wa.membership.api.model.Student') {
        history.replace('/errors/not-found');
      }
    },
    onError: () => {
      if (memberId && membershipId) {
        history.replace('/student/terms-and-conditions');
      } else {
        history.replace('/errors/general');
      }
    },

    retry: false,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  return <Route {...props} />;
};

export default StudentProtectedRoute;
