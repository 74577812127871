import shadows from '../shadows';

export default {
  styleOverrides: {
    root: {
      position: 'fixed',
      boxShadow: shadows[4],
    },
  },
};
