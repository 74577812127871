import React from 'react';

import { styled } from '@mui/material';
import MuiTypography, { TypographyProps } from '@mui/material/Typography';

const StyledTypography = styled(MuiTypography)(() => ({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '13px',
  lineHeight: '20px',
  fontWeight: 400,
}));

const Auth0SecondaryText = ({ children, ...props }: TypographyProps) => {
  return <StyledTypography {...props}>{children}</StyledTypography>;
};

export default Auth0SecondaryText;
