import React, { Suspense } from 'react';
import { renderRoutes, RouteConfig } from 'react-router-config';

import { LinearProgress } from '@mui/material';

import ErrorLayout from './ErrorLayout';

interface ErrorProps {
  route: RouteConfig;
}

const Error = ({ route }: ErrorProps) => (
  <Suspense fallback={<LinearProgress />}>
    <ErrorLayout>{renderRoutes(route.routes)}</ErrorLayout>
  </Suspense>
);

export default Error;
