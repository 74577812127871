import React, { ReactNode, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Link, Drawer, List, useTheme, useMediaQuery } from '@mui/material';

import { ReactComponent as LogoImage } from 'src/images/logo-light.svg';
import useMembership from 'src/providers/MembershipProvider';

import { Content, DashboardAppBar, StyledIconButton, StyledListItem } from '../styled';

interface DashboardLayoutProps {
  children: ReactNode;
}

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const { logout } = useAuth0();
  const {
    membership: { isInTrial },
  } = useMembership();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const history = useHistory();
  const { pathname } = useLocation();

  const signOut = () =>
    logout({
      returnTo: process.env.REACT_APP_ENV_NAME === 'prod' ? 'https://www.betterwise.com/' : window.location.origin,
    });
  const handleGoToStudentPage = () => {
    setMenuVisible(false);
    history.push('/dashboard');
  };
  const handleGoToAccountPage = () => {
    setMenuVisible(false);
    history.push('/dashboard/account');
  };

  return (
    <Box>
      <DashboardAppBar>
        <Box display="flex" justifyContent="space-between" alignItems="center" height="45px" mx="11px">
          <LogoImage width={125} />

          {isDesktop && (
            <Link onClick={signOut} color="text.secondary">
              Sign Out
            </Link>
          )}
          {!isDesktop && (
            <>
              <StyledIconButton
                size="large"
                aria-label="menu"
                sx={{ color: 'text.primary', fontSize: '2rem' }}
                onClick={() => setMenuVisible(true)}
              >
                <MenuIcon />
              </StyledIconButton>
              <Drawer anchor="top" open={menuVisible} onClose={() => setMenuVisible(false)}>
                <List sx={{ padding: 0 }}>
                  <StyledListItem selected={pathname === '/dashboard'} onClick={handleGoToStudentPage}>
                    Students
                  </StyledListItem>
                  <StyledListItem selected={pathname === '/dashboard/account'} onClick={handleGoToAccountPage}>
                    Account
                  </StyledListItem>
                  <StyledListItem onClick={signOut} color="text.secondary">
                    Sign Out
                  </StyledListItem>
                </List>
              </Drawer>
            </>
          )}
        </Box>
        {/* {isInTrial && <TrialBanner />} TODO: Removed for the Early Access Period */}
      </DashboardAppBar>

      <Content sx={{ marginTop: isInTrial ? '75px' : '45px' }}>{children}</Content>
    </Box>
  );
};

export default DashboardLayout;
