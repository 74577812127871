import React, { Suspense } from 'react';
import { renderRoutes, RouteConfig } from 'react-router-config';

import { Box, LinearProgress } from '@mui/material';

interface SponsorAuthProps {
  route?: RouteConfig;
}

const Auth = ({ route }: SponsorAuthProps) => (
  <Suspense fallback={<LinearProgress />}>
    <Box display="flex" justifyContent="center">
      {route && renderRoutes(route.routes)}
    </Box>
  </Suspense>
);

export default Auth;
