import React from 'react';

import { CircularProgress, styled } from '@mui/material';
import MuiButton, { ButtonProps } from '@mui/material/Button';

const StyledButton = styled(MuiButton)(({ theme }) => ({
  background: theme.palette.secondary.dark,
  borderRadius: '2px',
  maxWidth: '302px',
  boxShadow: 'none',
  fontFamily: "'Roboto', sans-serif",
  fontSize: '16px',
  lineHeight: '19px',
  fontWeight: 400,

  '&:hover': {
    background: theme.palette.secondary.dark,
    boxShadow: 'none',
  },
}));

interface Props extends ButtonProps {
  loading?: boolean;
}

const Auth0Button = ({ loading = false, children, disabled, ...props }: Props) => {
  return (
    <StyledButton {...props} disabled={disabled || loading}>
      {loading ? (
        <>
          <CircularProgress size={25} color="inherit" sx={{ marginRight: '15px' }} />
          Loading ..
        </>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default Auth0Button;
