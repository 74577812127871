import palette from '../palette';
import shadows from '../shadows';

export default {
  styleOverrides: {
    root: {
      height: '50px',
      background: palette.common.white,
      borderRadius: '11px',

      input: {
        background: palette.common.white,
        borderRadius: '11px',
        padding: '13px 16px',

        '&::placeholder': {
          color: palette.placeholder,
          opacity: 1,
        },

        '&:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 30px ${palette.common.white} inset`,
        },
      },

      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          marginRight: '-10px',
          color: palette.secondary.main,
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },

      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.common.black,
          borderWidth: '3px',
        },
      },

      ':not(.Mui-focused):not(.Mui-error)': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.common.white,
          boxShadow: shadows[3],
        },
      },

      '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
          borderWidth: '3px',
        },
      },
    },
  },
};
