import React from 'react';

import { styled, Typography } from '@mui/material';
import MuiButton, { ButtonProps } from '@mui/material/Button';

const StyledLinkButton = styled(MuiButton)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  height: '22px',
  boxShadow: 'none',
  padding: 0,
  border: 'none',
  background: 'transparent',

  '& .MuiTypography-root': {
    marginTop: 0,
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
  },

  '& .MuiButton-startIcon': {
    marginLeft: '0px',
  },

  '&:hover': {
    boxShadow: 'none',
    background: 'transparent',

    '& .MuiTypography-root': {
      textDecoration: 'underline',
    },
  },
}));

const ButtonLink = ({ children, ...props }: ButtonProps) => {
  return (
    <StyledLinkButton {...props} disableTouchRipple>
      <Typography variant="body1">{children}</Typography>
    </StyledLinkButton>
  );
};

export default ButtonLink;
