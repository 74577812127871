export default {
  fontFamily: "'AcuminPro', sans-serif",
  h1: {
    fontWeight: 600,
    fontSize: '27px',
    lineHeight: '35px',
    letterSpacing: '0.37px',
  },
  h4: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '25px',
    letterSpacing: '0.37px',
  },
  h5: {
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '0.37px',
    transform: 'translateY(3px)',
  },
  body1: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '0.37px',
    transform: 'translateY(3px)',
  },
  body2: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: '0.37px',
    transform: 'translateY(3px)',
  },
  button: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.37px',
  },
  caption: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: '0.37px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.37px',
    transform: 'translateY(3px)',
  },
};
