import React, { HTMLAttributes } from 'react';

import { ErrorWrapper } from '../styled';

interface ErrorLayoutProps {
  children: HTMLAttributes<HTMLDivElement>;
}

const ErrorLayout = ({ children }: ErrorLayoutProps) => (
  <ErrorWrapper>
    Error Layout
    {children}
  </ErrorWrapper>
);

export default ErrorLayout;
