import React from 'react';
import { useHistory } from 'react-router';

import { Box, Typography } from '@mui/material';

import Button from 'src/components/Button';

const EmailVerified = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/sponsor/plan');
  };

  return (
    <Box width="100%" maxWidth="500px" mt="100px">
      <Typography variant="h1" mb="11px">
        Email Verified!
      </Typography>

      <Typography variant="body1">Thank you for verifying your email address.</Typography>

      <Box display="flex" alignItems="center" justifyContent="center" mt="58px">
        <Button variant="contained" onClick={handleButtonClick} fullWidth>
          Next Step
        </Button>
      </Box>
    </Box>
  );
};

export default EmailVerified;
