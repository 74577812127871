import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, Checkbox, Link, styled } from '@mui/material';
import * as yup from 'yup';

import useApi from 'src/api';
import Button from 'src/components/Button';
import ButtonLink from 'src/components/Button/ButtonLink';
import EmailInput from 'src/components/EmailInput';
import ErrorMessage from 'src/components/ErrorMessage';
import NameInput from 'src/components/NameInput';
import { ReactComponent as ForwardStar } from 'src/images/star_forward.svg';

const schema = yup
  .object()
  .shape({
    name: yup.string().required('Full name is required'),
  })
  .required();

const TermsAndConditions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '30px',
  width: '100%',

  '.terms-and-conditions': {
    display: 'flex',

    '.button': {
      marginLeft: '5px',
    },

    '@media screen and (max-width: 400px)': {
      display: 'block',

      '.button': {
        marginLeft: 0,
      },
    },
  },
  '& .MuiCheckbox-root': {
    svg: {
      path: {
        fill: theme.palette.text.secondary,
      },
    },

    '&.Mui-checked': {
      svg: {
        path: {
          fill: theme.palette.text.secondary,
        },
      },
    },
  },
}));

const CreateMembership = () => {
  const history = useHistory();
  const { createMembership, checkAuth, getUser } = useApi();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);

  checkAuth();

  const methods = useForm<{ name: string; conditions: boolean }>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    formState: { isValid },
    handleSubmit,
  } = methods;

  const { mutateAsync, isLoading, isError } = useMutation(createMembership, {
    onError: ({ response }) => {
      setErrorMessage(response.data.message);
    },
  });

  // const { mutateAsync: earlyAccess } = useMutation(setMembershiptEarlyAccess, {
  //   onError: ({ response }) => {
  //     setErrorMessage(response.data.message);
  //   },
  // });

  const onSubmit = handleSubmit(async ({ name, email }: { name: string; email: string }) => {
    const user = await getUser();

    if (email && user) {
      const { data } = await mutateAsync({
        sponsorEmail: email,
        sponsorName: name,
        sponsorPhone: user.name,
      });

      if (data.membershipId) {
        // await earlyAccess();
        // history.push('/dashboard');
        history.push('/sponsor/plan');
      }
    }
  });

  return (
    <Box width="100%" maxWidth="500px" mt="100px">
      <Box display="flex" flexDirection="column">
        <Typography variant="h1" mb="21px">
          Welcome to Betterwise
        </Typography>

        <Typography mb="21px">
          Just a few more details to set up your account. The name you provide will be included in all student
          invitations.
        </Typography>

        {isError && <ErrorMessage message={errorMessage} />}

        <FormProvider {...methods}>
          <form noValidate onSubmit={onSubmit}>
            <Box display="flex" flexDirection="column">
              <NameInput placeholder="Full Name" />
              <EmailInput placeholder="Your Email" />
              <TermsAndConditions>
                <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                <Typography sx={{ transform: 'translateY(0)' }} className="terms-and-conditions">
                  <Typography>I agree to the</Typography>
                  <ButtonLink className="button">
                    <Link href="https://betterwise.com/terms/" target="_blank">
                      Terms of Service
                    </Link>
                  </ButtonLink>
                </Typography>
              </TermsAndConditions>
              <Button variant="contained" type="submit" disabled={!isValid || !isChecked} loading={isLoading}>
                Next Step
                {isValid && isChecked && <ForwardStar className="forward-star" />}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default CreateMembership;
