import palette from '../palette';
import shadows from '../shadows';

export default {
  styleOverrides: {
    root: {
      fontFamily: 'AcuminPro',
      textTransform: 'none',
      maxHeight: '50px',
      boxShadow: shadows[1],
      padding: '15px',
      borderRadius: '11px',
      color: palette.text.button,
      backgroundColor: palette.text.primary,
      '&:hover': {
        opacity: 0.7,
        backgroundColor: palette.text.primary,
        border: 'none',
        boxShadow: shadows[2],
      },
      '&.Mui-disabled': {
        opacity: 1,
        cursor: 'initial',
        backgroundColor: 'rgba(0,0,0,0.2)',
        color: 'rgba(0,0,0,0.5)',
      },
    },

    outlined: {
      color: palette.text.primary,
      backgroundColor: palette.foreground.default,
      border: 'none',
      borderRadius: '25px',
      minWidth: '200px',
      '&:hover': {
        backgroundColor: palette.foreground.default,
        border: 'none',
      },
    },
    contained: {
      border: 'none',
      borderRadius: '25px',
      minWidth: '200px',
    },
  },
};
