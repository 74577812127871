import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

/**
 * This component only serves the testing purpose, it is not needed in production
 */
const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    sessionStorage.clear();
    logout({
      returnTo: process.env.REACT_APP_ENV_NAME === 'prod' ? 'https://www.betterwise.com/' : window.location.origin,
    });
  }, [logout]);

  return <div>you have been logged out</div>;
};

export default Logout;
