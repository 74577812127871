import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      zIndex: 1500,

      '& .MuiCircularProgress-root': {
        color: palette.common.white,
      },

      '& .MuiTypography-root': {
        marginLeft: '15px',
        color: palette.common.white,
      },
    },
  },
};
