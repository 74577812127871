import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress } from '@mui/material';

const StudentInvite = () => {
  const { search } = useLocation();

  const membershipId = new URLSearchParams(search).get('membershipId');
  const memberId = new URLSearchParams(search).get('memberId');
  const { logout } = useAuth0();

  useEffect(() => {
    if (membershipId && memberId) {
      sessionStorage.setItem('membershipId', membershipId);
      sessionStorage.setItem('memberId', memberId);
      logout({
        returnTo: `${window.location.origin}/signup-student`,
      });
    }
  }, [memberId, membershipId, logout]);

  return <LinearProgress id="auth0-login-logout" />;
};

export default StudentInvite;
