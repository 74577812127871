import React from 'react';

import useApi from 'src/api';

const AuthLogin = () => {
  const { checkAuth } = useApi();
  const appState = new URLSearchParams(window.location.search).get('appState');
  const authType = new URLSearchParams(window.location.search).get('authType') || 'SMS';
  checkAuth(true, appState || undefined, authType);

  return (
    <div
      style={{
        height: '100vh',
      }}
      id="auth0-login"
    ></div>
  );
};

export default AuthLogin;
