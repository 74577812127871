import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import useApi from 'src/api';

const StudentSignup = () => {
  const { search } = useLocation();

  const authType = new URLSearchParams(search).get('authType') || 'SMS';
  const { checkAuth } = useApi();

  useEffect(() => {
    const membershipId = sessionStorage.getItem('membershipId');
    const memberId = sessionStorage.getItem('memberId');
    console.log(membershipId, memberId);
    checkAuth(true, '/student/terms-and-conditions', authType);
  }, [checkAuth, authType]);

  return <LinearProgress id="auth0-login" />;
};

export default StudentSignup;
