import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { Box, Link, Typography } from '@mui/material';

import { SUPPORT_LINK } from 'src/constants';

import { UserEmail } from './styled';

const VerifyEmail = () => {
  const { user } = useAuth0();

  if (user?.email_verified) {
    return <Redirect to="/sponsor/email-verified" />;
  }

  return (
    <Box width="100%" maxWidth="500px" mt="100px">
      <Typography variant="h1" mb="11px">
        Verify Email
      </Typography>

      <Box mb="33px">
        <UserEmail>{user?.email}</UserEmail>
      </Box>

      <Typography variant="body1" mb="21px">
        Please check your email for a link to verify your email address.
      </Typography>
      <Typography variant="body1" mb="21px">
        Didn’t receive an email?
        <Link
          href={
            SUPPORT_LINK +
            '?subject=Email verification support&body=Hi Support Team, can you verify my email? I never received my verification email.'
          }
          color="text.secondary"
          fontWeight={600}
          ml="3px"
        >
          Contact Support
        </Link>
      </Typography>
    </Box>
  );
};

export default VerifyEmail;
