import { AppBar, Box, IconButton, ListItem, styled } from '@mui/material';

export const DashboardAppBar = styled(AppBar)(() => ({
  display: 'flex',
  minHeight: '45px',
  background: '#FFF8F1',
}));

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 45,
  padding: '0 20px',

  [theme.breakpoints.only('tablet')]: {
    padding: '0 40px',
  },

  [theme.breakpoints.only('desktop')]: {
    padding: '0 137px',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  color: '#fff',
  fontSize: '2rem',
}));

export const StyledListItem = styled(ListItem)(() => ({
  height: 50,
  textAlign: 'center',
  borderBottom: '0.5px solid #C7C7C8',
}));

export const ErrorWrapper = styled(Box)(() => ({
  textAlign: 'center',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));
