import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import { Box, styled } from '@mui/material';

import AppBar from 'src/components/AppBar';

const StyledWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',

  '.react-pdf__Page__canvas': {
    width: 'auto !important',
    height: 'auto !important',
  },
}));

const StyledPdfWrapper = styled(Box)(() => ({
  marginTop: '80px',
  width: '100%',

  ['canvas']: {
    margin: 'auto',
  },
}));

const TermsAndPrivacy = ({ pdfFile }: { pdfFile: unknown }) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <StyledWrapper>
      <AppBar dark={false} />

      <StyledPdfWrapper>
        <Document file={pdfFile} options={{ workerSrc: '/pdf.worker.js' }} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (_, index) => (
            <Page scale={1} key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </StyledPdfWrapper>
    </StyledWrapper>
  );
};

export default TermsAndPrivacy;
