// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { renderRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';

import routes from './routes';

import './fonts/FuturaLTPro-Bold.otf';
import './fonts/AcuminPro-Medium.otf';

const tagManagerArgs = {
  gtmId: 'GTM-PJPP7VJ',
};
if (process.env.REACT_APP_ENV_NAME === 'prod') TagManager.initialize(tagManagerArgs);

// GTM Types
type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

const triggerEvent = () => {
  window.dataLayer.push({
    event: 'pageview',
  });
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: location.pathname });
};

ReactGA.initialize('G-4ED57KCKZ8');

const App = () => {
  const location = useLocation();
  window.dataLayer = [];

  useEffect(() => {
    if (process.env.REACT_APP_ENV_NAME === 'prod') {
      triggerEvent();
    }
  }, [location]);

  return renderRoutes(routes);
};

export default App;
