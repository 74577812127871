import Auth0Lock, { Auth0LockPasswordless } from 'auth0-lock';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

import {
  AcceptStudentInvitationBody,
  AddPaymentMethodResponse,
  AddProductRequest,
  CreateMembershipBody,
  CreateMembershipResponse,
  GetStripeProductsResponse,
  InviteStudentRequest,
  Member,
  Membership,
  PaymentMethod,
  Seat,
  Student,
  SuspendMembershipBody,
  PricePerSeatTypes,
  PricePerSeatResponse,
  AddExtraSeatsType,
} from './types';

let api: AxiosInstance;
let lockPasswordless: Auth0LockPasswordlessStatic;
let lock: Auth0LockStatic;

const domain = process.env.REACT_APP_AUTH0_DOMIAN as string;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE as string;
const authOptions = (appState: string) => ({
  auth: {
    audience,
    redirectUrl: window.location.origin + `?appState=${appState}`,
    responseType: 'token id_token',
    params: {
      scope: 'openid profile email',
    },
  },
  languageDictionary: {
    title: 'Sign Up or Login',
    submitLabel: 'Continue',
    signUpTerms:
      "By clicking continue, you agree to Betterwise’s <a href='https://betterwise.com/terms/' target='_blank'>Terms of Service</a> and <a href='https://betterwise.com/privacy' target='_blank'>Privacy Policy</a> and to receive personalized marketing text messages, study reminders, and other relevant account notifications.",
    passwordlessSMSInstructions:
      'Powering confident, independent futures by sharing knowledge. Login or create an account by entering your phone number.',
    passwordlessSMSCodeInstructions: 'We sent a 6-digit code to your phone number',
    welcome: 'test',
    mfaLoginTitle: 'Hkabjbdw',
  },
  theme: {
    logo: 'https://washington-dev-27f36.web.app/logo.png',
    primaryColor: '#0E0033',
  },
  closable: false,
  autoclose: true,
});

const useApi = () => {
  const setup = () => {
    const token = localStorage.getItem('AUTH_TOKEN');

    api = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  };

  if (api === undefined) {
    setup();
  }

  const checkAuth = (login = false, appState = '/dashboard', authType = 'SMS') => {
    lockPasswordless = new Auth0LockPasswordless(clientId, domain, {
      ...authOptions(appState),
      allowedConnections: ['sms'],
      passwordlessMethod: 'code',
    });
    lock = new Auth0Lock(clientId, domain, { ...authOptions(appState), initialScreen: 'signUp' });

    lockPasswordless.checkSession(
      {
        scope: 'openid profile email',
      },
      (error, authResult: AuthResult | undefined) => {
        if (authResult && !error) {
          localStorage.setItem('AUTH_TOKEN', authResult.idToken);
          localStorage.setItem('ACCESS_TOKEN', authResult.accessToken);
          setup();
          if (window.location.pathname === '/') {
            window.location.replace(appState);
          }
        } else {
          localStorage.removeItem('AUTH_TOKEN');
          localStorage.removeItem('ACCESS_TOKEN');
          setup();
          if (!login) {
            window.location.replace('/');
          }
          authType.toLowerCase() === 'sms' || process.env.REACT_APP_ENV_NAME === 'prod'
            ? lockPasswordless.show()
            : lock.show();
        }
      },
    );
  };
  const getUser = async () => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    let user: auth0.Auth0UserProfile | undefined;

    if (token) {
      const requestUser = new Promise((resolve, reject) => {
        lockPasswordless.getUserInfo(token, (error: auth0.Auth0Error, profile: auth0.Auth0UserProfile) => {
          if (error) {
            return reject(error);
          }
          return resolve(profile);
        });
      });
      await requestUser.then((result) => {
        user = result as auth0.Auth0UserProfile;
      });
    }
    return user;
  };

  const getStripeProducts = async (): Promise<AxiosResponse<GetStripeProductsResponse[]>> =>
    api.get(`/memberships/products`);

  const getQuote = async ({
    numberOfSeats,
    productId,
    couponCode,
  }: PricePerSeatTypes): Promise<AxiosResponse<PricePerSeatResponse>> =>
    api.get(
      `/memberships/quote?productId=${productId}&$&numberOfSeats=${numberOfSeats}
      &${couponCode ? `couponCode=${couponCode}` : ''}`,
    );

  const getMemberRole = async (): Promise<AxiosResponse<Member>> => api.get(`/memberships/members/role`);

  const getMembership = async (): Promise<AxiosResponse<Membership>> => api.get(`/memberships`);

  const createMembership = async (body: CreateMembershipBody): Promise<AxiosResponse<CreateMembershipResponse>> =>
    api.post(`/memberships`, body);

  const setMembershiptEarlyAccess = async (): Promise<AxiosResponse<any>> => api.post(`/memberships/early-access`); // Early Access endpoint

  const suspendMembership = async (body: SuspendMembershipBody): Promise<AxiosResponse<Membership>> =>
    api.post(`/memberships/cancellation`, body);

  const resumeMembership = async (): Promise<AxiosResponse<Membership>> => api.delete(`/memberships/cancellation`);

  const addProduct = async (variables: AddProductRequest): Promise<AxiosResponse<string>> => {
    const { productId, couponCode } = variables;

    let dataToSubmit: AddProductRequest = { productId };
    if (couponCode) {
      dataToSubmit = { ...dataToSubmit, couponCode };
    }

    return api.post(`/memberships/product`, dataToSubmit);
  };

  const addSeats = async ({ numberOfSeats, couponCode }: AddExtraSeatsType): Promise<AxiosResponse> =>
    api.put('/memberships/seats/adjust', couponCode ? { numberOfSeats, couponCode } : { numberOfSeats });

  const addExtraSeats = async ({ numberOfSeats, couponCode }: AddExtraSeatsType): Promise<AxiosResponse> =>
    api.put('/memberships/seats/add', couponCode ? { numberOfSeats, couponCode } : { numberOfSeats });

  const getSeats = async (): Promise<AxiosResponse<Seat[]>> => api.get(`/memberships/seats`);

  const inviteStudent = async (variables: InviteStudentRequest): Promise<AxiosResponse<Student>> => {
    const { studentEmail, studentName, dateOfBirth, studentPhone } = variables;

    return api.post(`/memberships/students`, {
      studentEmail,
      studentName,
      dateOfBirth,
      studentPhone,
    });
  };

  const revokeStudentInvitation = async (studentId: string): Promise<AxiosResponse<Student>> =>
    api.put(`/memberships/students/${studentId}/revoke-invitation`);

  const acceptStudentInvitation = async (body: AcceptStudentInvitationBody): Promise<AxiosResponse> =>
    api.post(`/memberships/students/acceptance`, body);

  const changePaymentMethod = async (paymentMethodId: string): Promise<AxiosResponse<AddPaymentMethodResponse>> =>
    api.put(`/memberships/payment-method`, { paymentMethodId });

  const getPaymentMethod = async (): Promise<AxiosResponse<PaymentMethod>> => api.get(`/memberships/payment-method`);

  const deleteSponsorAccount = async (): Promise<AxiosResponse> => api.delete('/memberships/sponsor');

  return {
    setup,
    getUser,
    checkAuth,
    getQuote,
    getMemberRole,
    getMembership,
    createMembership,
    suspendMembership,
    resumeMembership,
    addProduct,
    addSeats,
    addExtraSeats,
    getSeats,
    inviteStudent,
    revokeStudentInvitation,
    getStripeProducts,
    acceptStudentInvitation,
    changePaymentMethod,
    getPaymentMethod,
    deleteSponsorAccount,
    setMembershiptEarlyAccess,
  };
};

export default useApi;
