import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Route, RouteProps, useHistory } from 'react-router';

import { LinearProgress } from '@mui/material';

import useApi from 'src/api';

const ProtectedRoute = (props: RouteProps) => {
  const history = useHistory();

  const { getMembership, getMemberRole, checkAuth } = useApi();

  checkAuth();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSponsor, setIsSponsor] = useState<boolean>(false);

  useQuery('userRole', getMemberRole, {
    onSuccess: ({ data }) => {
      if (data.type === 'com.mindsea.wa.membership.api.model.Sponsor') {
        setIsSponsor(true);
      } else {
        history.replace('/student/download-app');
      }
    },
    onError: ({ response }) => {
      if (response.status === 500) {
        history.replace('/errors/general');
      }

      if ([401, 404].includes(response.status)) {
        history.replace('/create-membership');
      }
    },

    retry: false,
    refetchOnWindowFocus: false,
  });

  useQuery('membership', getMembership, {
    onSuccess: ({ data }) => {
      if (!data?.isActive) {
        history.replace('/sponsor/plan'); //removed for Early Access
        setIsLoading(false);
      } else {
        history.replace('/dashboard');
        setIsLoading(false);
      }
    },

    onError: ({ response }) => {
      if (response.status === 500) {
        history.replace('/errors/general');
      }
    },

    enabled: isSponsor,
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
