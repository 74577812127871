import { createTheme } from '@mui/material';

import breakpoints from './breakpoints';
import components from './components';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette,
  typography,
  components,
  breakpoints,
  shadows,
  shape: { borderRadius: 22 },
});

export default theme;
