import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import { Button, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';

import useApi from 'src/api';
import { ReactComponent as ForwardStar } from 'src/images/star_forward.svg';
import Auth0Checkbox from 'src/Temp/Auth0Checkbox';
import Auth0SecondaryText from 'src/Temp/Auth0SecondaryText';

const StyledLink = styled('a')(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  textDecoration: 'underline',

  ':visited': {
    color: theme.palette.text.secondary,
  },
}));

const StudentTermsAndConditions = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const history = useHistory();
  const { acceptStudentInvitation } = useApi();

  const membershipId = sessionStorage.getItem('membershipId');
  const memberId = sessionStorage.getItem('memberId');

  const { mutateAsync: acceptInvite } = useMutation(acceptStudentInvitation);

  const handleContinue = async () => {
    if (!!memberId && !!membershipId) {
      const results = await acceptInvite({ studentId: memberId, membershipId: membershipId });
      if (results.status === 200) {
        sessionStorage.removeItem('membershipId');
        sessionStorage.removeItem('memberId');
      }
      history.push('/student/download-app');
    }
  };

  return (
    <Box mb={20}>
      <Typography fontSize={35} lineHeight={1.2}>
        Terms & Conditions
      </Typography>

      <Auth0SecondaryText maxWidth="315px" my="10px">
        <Auth0Checkbox onChange={() => setIsChecked(!isChecked)} />I agree to the{' '}
        <StyledLink href="https://betterwise.com/terms/" target="_blank" rel="noreferrer">
          Terms & Conditions
        </StyledLink>
      </Auth0SecondaryText>

      <Button variant="contained" disabled={!isChecked} onClick={handleContinue}>
        Next Step
        {isChecked && <ForwardStar className="forward-star" />}
      </Button>
    </Box>
  );
};

export default StudentTermsAndConditions;
