import React from 'react';
import ReactGA from 'react-ga4';

import { Button, Typography } from '@mui/material';

import { DOWNLOAD_APP_LINK } from 'src/constants';
import { ReactComponent as AppStoreIcon } from 'src/icons/app-store.svg';

const Auth0AppStoreButton = () => {
  const link =
    process.env.REACT_APP_ENV_NAME === 'prod' ? DOWNLOAD_APP_LINK.AppStore : DOWNLOAD_APP_LINK.AppStoreTestFlight;

  const goToAppStore = () => {
    ReactGA.event({
      category: 'Outbound Click',
      action: 'Go to AppStore',
      label: 'App Store',
    });
    window.open(link, '_blank');
  };

  return (
    <Button onClick={goToAppStore} variant="contained" fullWidth>
      <Typography color="white">App Store</Typography>
      <AppStoreIcon className="backward-star" />
    </Button>
  );
};

export default Auth0AppStoreButton;
