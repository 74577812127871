import React, { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, styled, TextField, Typography, CircularProgress } from '@mui/material';

const StyledInput = styled(TextField)({
  width: '70%',
  '& .MuiOutlinedInput-root': {
    input: {
      textAlign: 'center',
    },
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    top: -50,
  },
});

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 30,
  marginBottom: 10,
  color: theme.palette.secondary.main,
}));

interface SeatsInputProps {
  totalPrice: string | number;
  hasSeat: boolean;
  isFetching: boolean;
  numCurrentSeat: number;
  onChange: (seats: number) => void;
}

const SeatsInput = ({ isFetching, hasSeat, numCurrentSeat, totalPrice, onChange }: SeatsInputProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useFormContext();

  useEffect(() => {
    if (hasSeat) setValue('seats', numCurrentSeat);
  }, [hasSeat, numCurrentSeat, setValue]);

  const onUpdateValue = (event: ChangeEvent<HTMLInputElement>) => {
    const removeSpecialChar = new RegExp(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi);
    const removeLettersChar = new RegExp(/^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g);

    const value = event.target.value.replaceAll(removeSpecialChar, '').replaceAll(removeLettersChar, '');

    if (Number(value) < 1) {
      setValue('seats', '');
      onChange(Number(0));
      setError('seats', { message: 'Please enter the number of seats you would like.' });
    } else {
      setValue('seats', value);
    }

    onChange(Number(value));

    if (Number(value) >= 1) {
      clearErrors('seats');
    }
  };

  return (
    <>
      <InputContainer>
        <StyledInput
          {...register('seats')}
          id="seats-input"
          fullWidth
          placeholder="Seats"
          defaultValue={hasSeat ? numCurrentSeat : 0}
          onChange={onUpdateValue}
          autoComplete="off"
          error={!!errors.seats}
          helperText={errors?.seats?.message}
        />
        <Typography variant="h4" lineHeight="20px">
          {isFetching ? (
            <CircularProgress size={25} />
          ) : (
            <>
              {totalPrice}
              <sub>
                <small>/yr</small>
              </sub>
            </>
          )}
        </Typography>
      </InputContainer>
    </>
  );
};

export default SeatsInput;
