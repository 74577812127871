import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Backdrop, CircularProgress, Link } from '@mui/material';

import useApi from 'src/api';
import { SUPPORT_LINK } from 'src/constants';
import { ReactComponent as VerifyEmailErrorImage } from 'src/images/verify-email-error.svg';
import { ReactComponent as VerifyEmailImage } from 'src/images/verify-email.svg';
import Auth0PrimaryText from 'src/Temp/Auth0PrimaryText';
import Auth0SecondaryText from 'src/Temp/Auth0SecondaryText';

import { AuthPageWrapper } from './styled';

const StudentVerifyEmail = () => {
  const { acceptStudentInvitation } = useApi();

  const membershipId = sessionStorage.getItem('membershipId');
  const memberId = sessionStorage.getItem('memberId');

  const [isInvitationInvalid, setIsInvitationInvalid] = useState<boolean>(false);

  const { isLoading } = useQuery(
    'acceptInvitation',
    () =>
      memberId && membershipId ? acceptStudentInvitation({ studentId: memberId, membershipId: membershipId }) : null,
    {
      onError: ({ response }) => {
        if (response.status === 404) {
          setIsInvitationInvalid(true);
        }
      },

      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      {!isLoading && (
        <AuthPageWrapper>
          {isInvitationInvalid ? (
            <>
              <VerifyEmailErrorImage aria-hidden="true" />

              <Auth0PrimaryText my="18px" textAlign="center" maxWidth="200px">
                Your invitation link is no longer vaild
              </Auth0PrimaryText>

              <Auth0SecondaryText textAlign="center">
                Try asking the person who sent it to you for a new link.
              </Auth0SecondaryText>
            </>
          ) : (
            <>
              <VerifyEmailImage aria-hidden="true" />

              <Auth0PrimaryText my="18px" textAlign="center">
                Verify Your Email
              </Auth0PrimaryText>

              <Auth0SecondaryText textAlign="center" maxWidth="232px">
                Please check &nbsp; for a link to verify your email address.
              </Auth0SecondaryText>

              <Auth0SecondaryText textAlign="center" mt="18px">
                Wrong email address? Please
                <Link href={SUPPORT_LINK} color="text.primary" fontWeight={600} ml="3px">
                  contact support
                </Link>
                .
              </Auth0SecondaryText>
            </>
          )}
        </AuthPageWrapper>
      )}

      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default StudentVerifyEmail;
