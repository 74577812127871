import { Components } from '@mui/material';

import MuiAppBar from './MuiAppBar';
import MuiBackdrop from './MuiBackdrop';
import MuiButton from './MuiButton';
import MuiDivider from './MuiDivider';
import MuiFormHelperText from './MuiFormHelperText';
import MuiLink from './MuiLink';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiTypography from './MuiTypography';

export default {
  MuiAppBar,
  MuiBackdrop,
  MuiButton,
  MuiDivider,
  MuiLink,
  MuiTypography,
  MuiOutlinedInput,
  MuiFormHelperText,
} as Components;
