import shadows, { Shadows } from '@mui/material/styles/shadows';

shadows[1] = '1px 2px 4px rgba(0, 0, 0, 0.25)'; // Button
shadows[2] = '0px 0px 4px rgba(0, 0, 0, 0.5)'; // Hovered button
shadows[3] = '0px 1px 4px rgba(0, 0, 0, 0.25);'; // Hovered input / List / ListItemButton / ErrorMessage / SummaryList
shadows[4] = '0px 1px 0px rgba(14, 0, 51, 0.15)'; // AppBar
shadows[5] = '0px 8px 30px rgba(0, 0, 0, 0.15)';
shadows[6] = 'inset 0px 1px 4px rgba(0, 0, 0, 0.25)';

export default shadows as Shadows;
