import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Redirect, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import * as yup from 'yup';

import useApi from 'src/api';
import { GetStripeProductsResponse, Membership } from 'src/api/types';
import Button from 'src/components/Button';
import CouponInput from 'src/components/CouponInput';
import ErrorMessage from 'src/components/ErrorMessage';
import SeatsInput from 'src/components/SeatsInput';
import { ReactComponent as ForwardStar } from 'src/images/star_forward.svg';
import { asMoney } from 'src/utils/amount';

interface PlanState {
  seats: number;
  couponCode?: string;
}

const Plan = () => {
  const history = useHistory();
  const { addProduct, getStripeProducts, getMembership, getQuote } = useApi();
  const { state } = useLocation<{ seats: number }>();

  const [products, setProducts] = useState<GetStripeProductsResponse[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [membership, setMembership] = useState<Membership>();
  const [currentSeats, setCurrentSeats] = useState<number>(state?.seats);
  const [couponCode, setCouponCode] = useState<string | undefined>(sessionStorage.getItem('couponCode') || undefined);
  const [priceData, setPriceData] = useState<string>();

  const productId = products?.[0]?.productId;
  const hasSeat = currentSeats > 0;
  const schema = yup
    .object()
    .shape({
      seats: yup.number().min(1).typeError('Please enter the number of seats you would like.'),
    })
    .required();
  const methods = useForm<PlanState>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const { isLoading: isLoadingMembership } = useQuery('membership', getMembership, {
    onSuccess: ({ data }) => setMembership(data),
    onError: () => history.replace('/'),
    refetchOnWindowFocus: false,
  });

  const { isLoading: isLoadingProducts, isError: isGetProductsError } = useQuery('products', getStripeProducts, {
    onSuccess: ({ data }) => setProducts(data),
    onError: () => setErrorMessage('Something went wrong, try again later'),
    refetchOnWindowFocus: false,
  });

  const { refetch: fetchPrice, isFetching: isFetchingPrice } = useQuery(
    'pricePerSeat',
    () => (productId ? getQuote({ numberOfSeats: currentSeats || 0, productId, couponCode }) : null),
    {
      onSuccess: (response) => {
        if (response) {
          const { amount } = response.data.dueToday.amount;
          setPriceData(asMoney(amount));
        }
      },
      onError: ({ response }) => {
        setErrorMessage(response.data.message);
        return response;
      },
      refetchOnWindowFocus: false,
      retry: 0,
    },
  );

  const {
    mutateAsync: addProductMutateAsync,
    isLoading: isAddProductLoading,
    isError: isAddProductError,
  } = useMutation(addProduct, {
    onError: ({ response }) => setErrorMessage(response.data.message),
  });

  const seatPrice = priceData || '0.00';
  const showBackdrop = isLoadingMembership || isLoadingProducts;

  useEffect(() => {
    if (productId && currentSeats > -1) fetchPrice();
    else setPriceData('0.00');
  }, [currentSeats, couponCode, productId, fetchPrice]);

  if (membership?.isActive) {
    return <Redirect to="/dashboard" />;
  }

  const onSubmit = methods.handleSubmit(async ({ seats }: PlanState) => {
    if (seats && products.length >= 0) {
      const response = await fetchPrice();
      if (response.status === 'error') return setErrorMessage(response.error?.response?.data?.message || '');

      let clientSecret = sessionStorage.getItem('clientSecret');
      if (!clientSecret || !productId) {
        const { data } = await addProductMutateAsync({ productId, couponCode });
        clientSecret = data;
        sessionStorage.setItem('clientSecret', data);
      }

      return history.push('/sponsor/payment', {
        membershipId: membership?.id,
        productId,
        clientSecret,
        seats,
        totalPrice: seatPrice,
        dueOn: response?.data?.data?.dueToday.dueOn || '',
        couponCode,
      });
    }
  });

  return (
    <>
      {showBackdrop ? (
        <Backdrop open={true}>
          <CircularProgress />
        </Backdrop>
      ) : (
        <Box width="100%" maxWidth="387px" mt="100px">
          <Typography fontSize={40} lineHeight={1.2} mb="11px">
            Choose the number of seats
          </Typography>

          {/* TODO: Removed for Paid Access
          <Typography variant="body1" mb="21px">
            Free for 30 days
          </Typography>
          */}
          {(isAddProductError || isGetProductsError || errorMessage) && <ErrorMessage message={errorMessage} />}

          <FormProvider {...methods}>
            <form noValidate onSubmit={onSubmit}>
              <Box display="flex" flexDirection="column">
                <Box width="100%" position="relative">
                  <SeatsInput
                    isFetching={isFetchingPrice}
                    hasSeat={hasSeat}
                    numCurrentSeat={currentSeats}
                    totalPrice={seatPrice}
                    onChange={(seats) => {
                      setErrorMessage('');
                      setCurrentSeats(seats);
                    }}
                  />
                </Box>

                <Box width="100%" mb={5}>
                  <CouponInput
                    onPress={(code) => {
                      setErrorMessage('');
                      setCouponCode(code || undefined);
                    }}
                  />
                </Box>

                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isGetProductsError || !hasSeat}
                  loading={isAddProductLoading || isLoadingProducts || isFetchingPrice}
                >
                  Next Step
                  {!isGetProductsError && hasSeat && <ForwardStar className="forward-star" />}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
      )}
    </>
  );
};

export default Plan;
