import React from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route, RouteProps } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import useApi from 'src/api';

const SponsorProtectedRoute = (props: RouteProps) => {
  const location = useLocation();
  const history = useHistory();
  const isAuthenticated = !!localStorage.getItem('AUTH_TOKEN');

  const { getMemberRole } = useApi();

  const { isLoading } = useQuery('userRole', getMemberRole, {
    onSuccess: ({ data }) => {
      if (data.type !== 'com.mindsea.wa.membership.api.model.Sponsor') {
        history.replace('/errors/not-found');
      }
    },
    onError: ({ response }) => {
      if ([401, 404].includes(response.status)) {
        history.replace('/sponsor/plan');
      } else history.replace('/errors/general');
    },

    enabled: isAuthenticated,
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  return <Route {...props} />;
};

export default SponsorProtectedRoute;
