declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    desktop: true;
  }
}

export default {
  values: {
    mobile: 0,
    tablet: 835,
    desktop: 1440,
  },
};
