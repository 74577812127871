import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import PaymentForm from './PaymentForm';
import { appearance } from './styled';

interface StripeContainerProps {
  clientSecret: string;
  membershipId: string;
  productId: string;
  seats: number;
  couponCode?: string;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

const StripeContainer = ({ clientSecret, ...otherProps }: StripeContainerProps) => {
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
    locale: 'en',
  };

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentForm {...otherProps} />
        </Elements>
      )}
    </>
  );
};

export default StripeContainer;
