import React, { useState, ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Button, styled, TextField } from '@mui/material';
import { debounce } from 'lodash';

interface CouponInputProps {
  onPress: (coupon: string | undefined) => void;
}

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.secondary.main,
  marginTop: '10px',
}));

const StyledInput = styled(TextField)({
  marginRight: 10,
  '& .MuiOutlinedInput-root': {
    height: '100%',
    input: {
      textAlign: 'center',
    },
  },
});

const CouponInput = ({ onPress }: CouponInputProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const defaultCoupon = sessionStorage.getItem('couponCode');
  const [hasCoupon, setHasCoupon] = useState<boolean>(!!defaultCoupon);

  const handleChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue('couponCode', value);
    sessionStorage.setItem('couponCode', value);
    if (value.trim() === '') {
      setHasCoupon(!hasCoupon);
      setValue('couponCode', null);
      sessionStorage.removeItem('couponCode');
      onPress(undefined);
    }
  }, 500);

  return (
    <InputContainer>
      <StyledInput
        {...register('couponCode')}
        placeholder="Coupon Code"
        defaultValue={defaultCoupon}
        onChange={handleChange}
        type="text"
        autoComplete="off"
        error={!!errors.couponCode}
        helperText={errors?.couponCode?.message}
      />
      <Button size="small" onClick={() => onPress(getValues('couponCode'))} sx={{ marginTop: '3px' }}>
        Apply
      </Button>
    </InputContainer>
  );
};

export default CouponInput;
