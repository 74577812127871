declare module '@mui/material/styles' {
  interface Palette {
    foreground: PaletteOptions['foreground'];
    requirements: PaletteOptions['requirements'];
    liquidColor: PaletteOptions['liquidColor'];
    placeholder: PaletteOptions['placeholder'];
    segmentedController: PaletteOptions['segmentedController'];
  }
  interface PaletteOptions {
    foreground: {
      default: string;
      pressed: string;
    };
    requirements: {
      light: string;
      dark: string;
    };
    liquidColor: string;
    placeholder: string;
    segmentedController: string;
    banner: string;
  }
}

export default {
  primary: {
    main: '#E0732C',
    light: '#F17D31',
    dark: '#4800B7',
  },
  secondary: {
    main: '#4A4066',
    light: '#766E8B',
    dark: '#0E0033',
  },
  common: {
    white: '#FFF',
    black: '#000',
  },
  foreground: {
    default: '#FFFFFF',
    pressed: '#F5EEFF',
  },
  requirements: {
    light: '#766E8B',
    dark: '#0E0033',
  },
  liquidColor: 'rgba(14, 0, 51, 0.25)',
  placeholder: '#766E8B',
  banner: '#04000F',
  segmentedController: '#E0E0E1',
  error: {
    main: '#DA0000',
    light: 'rgba(218, 0, 0, 0.08)',
  },
  text: {
    primary: '#000000',
    secondary: '#4800B7',
    button: '#FFFFFF',
    disabled: '#636364',
  },
  background: {
    default: '#FFF8F1',
  },
  action: {
    disabled: 'rgba(0,0,0,0.5)',
    disabledBackground: 'rgba(0,0,0,0.2)',
  },
  unchosen: {
    default: '#F9EDE3',
  },
  actionPurple: {
    default: '#4702B5',
  },
  dividerLight: '#CCC7C1',
};
