import { Box, List, styled } from '@mui/material';

export const FormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('tablet')]: {
    alignItems: 'center',
  },
}));

export const SummaryList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '21px',
  background: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  padding: 0,

  '& .MuiListItem-root': {
    padding: '13px 16px',
    borderBottom: '0.1px solid',
    borderColor: theme.palette.action.disabledBackground,

    '&.MuiListItemText-root': {
      margin: 0,
    },
  },

  '& .MuiListItem-root#sub-total': {
    padding: '13px 16px',
    border: 'none',

    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontWeight: 600,
      },
    },
  },
}));

export const AuthPageWrapper = styled(Box)(({ theme }) => ({
  width: '378px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.up('tablet')]: {
    padding: '38px',
    boxSizing: 'border-box',
    background: theme.palette.common.white,
    borderRadius: '4px',
    boxShadow: theme.shadows[5],
  },
}));

export const UserEmail = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.light,
  maxWidth: '500px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 500,

  [theme.breakpoints.only('mobile')]: {
    maxWidth: '310px',
  },
}));
